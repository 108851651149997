exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-test-booking-js": () => import("./../../../src/pages/test-booking.js" /* webpackChunkName: "component---src-pages-test-booking-js" */),
  "component---src-templates-wp-boat-js": () => import("./../../../src/templates/WpBoat.js" /* webpackChunkName: "component---src-templates-wp-boat-js" */),
  "component---src-templates-wp-itinerary-js": () => import("./../../../src/templates/WpItinerary.js" /* webpackChunkName: "component---src-templates-wp-itinerary-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */)
}

